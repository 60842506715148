import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { MT5DesktopQuery } from "../../prismic/staticQueries";
import { connect, useSelector } from "react-redux";

import Layout from "../../layout";
import { BreadCrumbBanner, Subscription } from "../../components/common";
import BANNER from "../../images/siteImages/mt4Desktop/MT4_DESKTOP_BANNER.jpg";
import MT4_DESKTOP_DOWNLOAD from "../../images/siteImages/mt4Desktop/MT4_DESKTOP_DOWNLOAD.png";
import PaymentSlider from "../Company/PaymentSlider";

const OurMission = () => {
  const language = useSelector((state) => state.language);
  const {
    banner_image,
    main_buttons,
    content_heading,
    download_box,
    content_image,
    content_body,
  } = MT5DesktopQuery(language);
  return (
    <>
      <Layout>
        <BreadCrumbBanner
          showButtons={false}
          heading={""}
          banner_image={BANNER}
          buttons={main_buttons}
        />
        {/* <QuickStats heading={stats_heading} items={stats_items} /> */}

        <section className="job-contents p-top-20 p-bottom-65 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h3 className="text-center pt-5">{content_heading.text}</h3>
                {/* <p>{content_description.text}</p> */}
                <PrismicRichText field={content_body.richText} />
                <img
                  src={MT4_DESKTOP_DOWNLOAD}
                  style={{ padding: "2rem 0rem" }}
                  alt="MT5"
                />
                {/*  <p className="text-center">
                  <a
                    href={download_link.text}
                    style={{ backgroundColor: "#1175bb", color: "#fff", marginTop: '3rem' }}
                    className="btn  rounded-0 btn-icon icon-right"
                  >
                    {download_label.text}
                    <span className="la la-download"></span>
                  </a>
                </p> */}
              </div>
              <div className="col-lg-12 text-center">
                {download_box.map((item, itemInd) => (
                  <a
                    key={"ddl" + itemInd}
                    href={item.download_link.text}
                    className="btn  rounded-0 btn-icon icon-right"
                    style={{
                      backgroundColor: "#1175bb",
                      color: "#fff",
                      width: "25rem",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.download_label.text}{" "}
                    <span className="la la-download"></span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>

        
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(OurMission);
